<template>
  <div>
    <div class="md:flex justify-between md:w-10/12 mx-auto py-4 md:py-8 text-center md:text-left lg:text-other">
      <div class="justify-center md:flex">
        <div class="md:mr-20">2023</div>
        <div>All Rights Reserved</div>
      </div>
      <!--
        <div class="justify-end md:flex">
        <div class="md:mr-20">Politique de Confidalité</div>
        <div>Conditions D'utilisation</div>
      </div>-->
    </div>
    <div class="md:flex justify-center py-2 md:w-10/12 mx-auto text-center">
      <div>Developed by STInSoft</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
