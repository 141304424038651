<template>
  <ContactForm data-aos="zoom-in" />
</template>

<script>
import ContactForm from "../components/Contact/ContactForm.vue";
export default {
  components: { ContactForm },
};
</script>

<style></style>