<template>
  <div class="text-primary md:flex my-5 px-5 mb-8 md:mb-0">
    <div class="w-7/12 hidden md:flex justify-center items-center">
      <img :src="pic1" class="pic" alt="water" />
    </div>
    <div class="md:w-5/12 md:pt-20">
      <div class="md:w-8/12 md:ml-10">
        <h1 class="2xl:text-5xl text-4xl font-Sitka font-bold text-primary">
          Qualité algérienne, portée internationale.
        </h1>
        <p class="my-4 lg:text-other text-lg">
          Grâce à notre engagement continu envers l'innovation, nous développons constamment de nouvelles technologies
          pour améliorer l'efficacité et la performance de nos produits. Faites confiance à Techno Chof pour une
          expérience de plomberie de pointe.
        </p>
        <!-- <button class="bg-primary lg:text-other text-lg text-white w-full py-3 px-2 rounded-full shadow-md">
          Description
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import pic1 from "../../assets/pic1.jpg";
export default {
  data() {
    return {
      pic1: pic1,
    };
  },
};
</script>

<style scoped>
.pic {
  margin: auto;
  width: 90%;
  height: 550px;
  border-radius: 1.5rem;
}
</style>
