<template>
    <div class="text-primary md:flex my-5 px-5 ">
        <div class="md:w-1/2">
            <div class="md:w-8/12 md:ml-10">
                <h1 class="2xl:text-5xl text-4xl font-Sitka font-bold text-primary">
                    Foire Aux Questions Techno Chof
                </h1>
                <p class="my-4 lg:text-other text-lg">
                    Nous espérons que vous trouverez ici les réponses aux questions les plus fréquemment posées. Si vous
                    ne trouvez pas ce que vous cherchez, n'hésitez pas à nous contacter.
                </p>
                <!-- <button class="bg-primary lg:text-other text-lg text-white w-full py-3 px-2 rounded-full shadow-md">
          Description
        </button> -->
            </div>
        </div>
        <div class="md:w-1/2">
            <div class="faq" v-for="(faq, index) in faqItems" :key="index">
                <p class="question" @click="toggleAnswer(faq)">
                    {{ faq.q }}
                </p>
                <p class="answer" v-if="faq.isOpen">
                    {{ faq.a }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // app.js
    data() {
        return {
            isOpen: false,

            faqItems: [
                {
                    q: "Où puis-je acheter les produits Techno Chof?",
                    a: "Vous pouvez trouver nos produits chez une sélection de revendeurs agréés à travers l'Algérie.",
                    isOpen: false
                },
                {
                    q: "Offrez-vous des garanties sur vos produits ?",
                    a: "Oui, tous les produits Techno Chof bénéficient d'une garantie fabricant. La durée de la garantie varie selon le produit. Vous trouverez plus d'informations sur la garantie dans la notice d'utilisation fournie avec votre produit.",
                    isOpen: false
                },
                {
                    q: "En quoi les produits Techno Chof sont-ils différents des autres marques?",
                    a: "Nous nous engageons à fournir des produits de qualité supérieure fabriqués en Algérie, en utilisant des technologies innovantes et des matériaux durables. Nous nous concentrons également sur l'esthétique et offrons une large série de styles pour s'adapter à tous les goûts.",
                    isOpen: false
                },
            ]
        };
    },
    methods: {
        toggleAnswer(item) {
            item.isOpen = !item.isOpen;
        }
    }
}
</script>

<style>
/* basic styles  */
* {
    box-sizing: border-box;
}


::selection {
    user-select: none;
}

/* style the FAQ section */
.question {
    background: hsl(35 10% 30% / 0.1);
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0px 4px 0px 0 #88888855;
    padding: 10px;
    transition: transform 0.2s;
    position: relative;
}

.question:hover {
    background: hsl(35 10% 30% / 0.15);
}

.question::before {
    content: "";
    margin: 10px;
}

/* styles when the question is clicked */
.question:active {
    transform: translateY(4px);
    box-shadow: none;
}

.answer {
    overflow: hidden;
    line-height: 1.5;
    padding: 10px;
}

.answer::before {
    content: "";
    margin-right: 10px;
}

/* style the toggleIcon */
.toggleIcon {
    font-size: 1.5em;
    font-weight: bold;
    position: absolute;
    right: 20px;
    display: inline-block;
    line-height: 0.5;
    color: #666;
}
</style>