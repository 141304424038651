<template>
  <div id="app" class="relative shadow">
    <NavBar />
    <router-view />
    <FooterSec v-if="$route.name != 'contact'" />
    <CopyRights v-if="$route.name != 'contact'" />
    <button
      :class="{
        'opacity-0': !toTopButtonIsActive,
      }"
      id="to-top-button"
      @click="moveToTop"
    >
      &#8593;
    </button>
  </div>
</template>
<script>
import NavBar from "./components/NavBar.vue";
import FooterSec from "./components/FooterSec.vue";
import CopyRights from "./components/CopyRights.vue";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  duration: 750,
});
export default {
  components: {
    NavBar,
    FooterSec,
    CopyRights,
  },
  created() {
    window.addEventListener("scroll", this.scroll);
    console.log();
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll);
  },
  data() {
    return {
      toTopButtonIsActive: false,
    };
  },
  methods: {
    moveToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    scroll() {
      if (
        document.body.scrollTop > 40 ||
        document.documentElement.scrollTop > 40
      ) {
        this.toTopButtonIsActive = true;
      } else {
        this.toTopButtonIsActive = false;
      }
    },
  },
};
</script>

<style>
#to-top-button {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  width: 3rem;
  height: 3rem;
  transition-duration: 300ms;
  border-radius: 50%;
  background-color: #ebedef;
  font-size: 32px;
  font-weight: bold;
  --tw-shadow: 0 3px 6px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app {
  max-width: 100vw;
  overflow: hidden;
  background-color: #e4e4e4;
  min-height: 100vh;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  scroll-behavior: smooth;
  font-family: Roboto;
}
</style>
