<template>
  <footer>
    <div
      class="md:flex px-10 md:px-20 bg-footerBg py-12 md:py-24 text-primary text-center md:text-left justify-between"
    >
      <div class="md:w-12/12 hidden md:block">
        <ul>
          <li class="2xl:text-5xl text-3xl font-Sitka font-semibold mb-10">
            Contact
          </li>
          <li class="my-4 lg:text-other text-lg">
            <a href="">Address: Zone industrielle commune Aîn Yagout BATNA</a>
          </li>
          <li class="my-4 lg:text-other text-lg">
            <a href="">Email : sales@technochof.com</a>
          </li>
          <li class="my-4 lg:text-other text-lg">
            <a href="">Mobile : +213 777 999 984</a>
          </li>
          <li class="my-4 lg:text-other text-lg">
            <a href="">Mobile : +213 777 999 968</a>
          </li>
          <li class="my-4 lg:text-other text-lg">
            <a href="">Mobile : +213 0777 999 972 </a>
          </li>
        </ul>
      </div>
      <!-- <div class="md:w-2/12 hidden md:block">
        <ul>
          <li class="2xl:text-5xl text-3xl font-Sitka font-semibold mb-10">
            Services
          </li>
          <li class="my-4 lg:text-other text-lg"><a href="">Service 1</a></li>
          <li class="my-4 lg:text-other text-lg"><a href="">Service 1</a></li>
          <li class="my-4 lg:text-other text-lg"><a href="">Service 1</a></li>
          <li class="my-4 lg:text-other text-lg"><a href="">Service 1</a></li>
        </ul>
      </div>
      <div class="md:w-2/12 hidden md:block">
        <ul>
          <li class="2xl:text-5xl text-3xl font-Sitka font-semibold mb-10">
            Ressources
          </li>
          <li class="my-4 lg:text-other text-lg"><a href="">Blog</a></li>
          <li class="my-4 lg:text-other text-lg"><a href="">Blog</a></li>
          <li class="my-4 lg:text-other text-lg"><a href="">Blog</a></li>
          <li class="my-4 lg:text-other text-lg"><a href="">Blog</a></li>
        </ul>
      </div> -->

      <div class="md:hidden flex mt-8 text-left">
        <div class="w-12/12">
          <ul>
            <li class="2xl:text-5xl text-3xl font-Sitka font-semibold mb-10">
              Contact
            </li>
            <li class="my-4 lg:text-other text-lg">
              <a href="">Address: Zone industrielle commune Aîn Yagout BATNA</a>
            </li>
            <li class="my-4 lg:text-other text-lg">
              <a href="">Email : sales@technochof.com</a>
            </li>
            <li class="my-4 lg:text-other text-lg">
              <a href="">Mobile : +213 777 999 984</a>
            </li>
            <li class="my-4 lg:text-other text-lg">
              <a href="">Mobile : +213 777 999 968</a>
            </li>
            <li class="my-4 lg:text-other text-lg">
              <a href="">Mobile : +213 0777 999 972 </a>
            </li>
          </ul>
        </div>
        <!-- <div class="w-4/12">
          <ul>
            <li class="2xl:text-5xl text-3xl font-Sitka font-semibold mb-10">
              Services
            </li>
            <li class="my-4 lg:text-other text-lg">
              <a href="">Service 1</a>
            </li>
            <li class="my-4 lg:text-other text-lg">
              <a href="">Service 1</a>
            </li>
            <li class="my-4 lg:text-other text-lg">
              <a href="">Service 1</a>
            </li>
            <li class="my-4 lg:text-other text-lg">
              <a href="">Service 1</a>
            </li>
          </ul>
        </div>
        <div class="w-4/12">
          <ul>
            <li class="2xl:text-5xl text-3xl font-Sitka font-semibold mb-10">
              Ressources
            </li>
            <li class="my-4 lg:text-other text-lg"><a href="">Blog</a></li>
            <li class="my-4 lg:text-other text-lg"><a href="">Blog</a></li>
            <li class="my-4 lg:text-other text-lg"><a href="">Blog</a></li>
            <li class="my-4 lg:text-other text-lg"><a href="">Blog</a></li>
          </ul>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
import { mdiFacebook } from "@mdi/js";
import { mdiTwitter } from "@mdi/js";
import { mdiLinkedin } from "@mdi/js";
import { mdiYoutube } from "@mdi/js";
import { mdiPhone } from "@mdi/js";

export default {
  name: "FooterSec",
  data() {
    return { mdiFacebook, mdiTwitter, mdiLinkedin, mdiYoutube, mdiPhone };
  },
};
</script>

<style></style>
