<template>
  <!-- About Header Image  -->
  <div id="section-bg" class="relative top-2 p-6 max-w-screen h-48">
    <h2 class="lg:text-5xl text-4xl font-bold font-Sitka text-primary">
      Nos Produits
    </h2>
    <img data-aos="zoom-in"
      class="rounded-3xl absolute md:top-4 top-8 left-12 lg:w-56 lg:h-48 md:w-44 md:h-36 w-20 h-24" :src="faucet1PicURL"
      alt="faucet-1" />
    <img data-aos="zoom-in" class="rounded-3xl absolute md:top-20 top-4 right-10 md:w-40 md:h-28 w-16 h-16"
      :src="faucet4PicURL" alt="faucet-4" />
    <img data-aos="zoom-in" class="rounded-3xl absolute bottom-8 right-12 w-28 h-28 blur-sm" :src="faucet2PicURL"
      alt="faucet-2" />
    <img data-aos="zoom-in" class="rounded-3xl absolute -bottom-4 -left-4 w-24 h-24 blur-sm" :src="faucet3PicURL"
      alt="faucet-3" />
    <img data-aos="zoom-in" class="rounded-3xl absolute bottom-24 md:left-40 left-20 w-40 h-32 md:block hidden"
      :src="faucet5PicURL" alt="faucet-5" />
  </div>
</template>

<script>
import faucet1 from "@/assets/faucet1.webp";
import faucet2 from "@/assets/faucet2.jpeg";
import faucet3 from "@/assets/faucet3.jpeg";
import faucet4 from "@/assets/faucet4.jpeg";
import faucet5 from "@/assets/faucet5.jpeg";

export default {
  data() {
    return {
      faucet1PicURL: faucet1,
      faucet2PicURL: faucet2,
      faucet3PicURL: faucet3,
      faucet4PicURL: faucet4,
      faucet5PicURL: faucet5,
    };
  },
};
</script>

<style scoped>
img {
  border: 1px solid white;
}

#section-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  background: rgb(191, 191, 191);
  background: linear-gradient(0deg,
      rgba(191, 191, 191, 1) 0%,
      rgba(205, 205, 205, 1) 39%,
      rgba(231, 231, 233, 1) 100%);
}
</style>
