<template>
  <div class="">
    <SectionOne data-aos="fade-left" />
    <SectionTwo data-aos="fade-right" />
    <!-- <SectionThree data-aos="fade-left" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import SectionOne from "../components/Home/SectionOne.vue";
import SectionTwo from "../components/Home/SectionTwo.vue";
// import SectionThree from "../components/Home/SectionThree.vue";

export default {
  name: "HomeView",
  components: {
    SectionOne,
    SectionTwo,
    // SectionThree,
  },
};
</script>
