<template>
  <div class="bg-mainBg max-w-screen overflow-hidden">
    <SectionOne />
    <SectionTwo />
    <!-- <SectionThree/> -->

  </div>
</template>
<script>
import SectionOne from "@/components/Products/SectionOne.vue"
import SectionTwo from "@/components/Products/SectionTwo.vue"
// import SectionThree from "@/components/Products/SectionThree.vue"
export default {
  components: {
    SectionOne,
    SectionTwo,
    // SectionThree,
  }
}
</script>
<style scoped></style>