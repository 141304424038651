<template>
  <div class="relative flex flex-col items-center bg-mainBg w-screen p-6">
    <h2 class="who-we-are w-screen flex text-primary relative">
      <span class="mx-8 2xl:text-5xl text-4xl font-Sitka font-bold"
        >QUI SOMMES-NOUS ?</span
      >
    </h2>
    <div
      data-aos="zoom-in"
      class="bg-mainBg mx-auto md:w-2/3 w-screen my-20 px-12 pb-20 text-center"
    >
      <h2 class="md:text-5xl text-4xl text-primary my-12 font-bold font-Sitka">
        Née d'une ambition, façonnée par l'expérience
      </h2>
      <p class="lg:text-other">
        En 2020, les frères Louhabi, animés par une volonté farouche de
        contribuer à l'essor de l'industrie algérienne, fondent Techno Chof.
        Forts de leur expertise dans le domaine de l'import-export et nourris
        par une profonde connaissance du marché national, ils se lancent dans un
        défi ambitieux : développer une production algérienne de robinetterie de
        haute qualité.
      </p>
    </div>
    <div
      data-aos="fade-up"
      class="w-full flex md:flex-row flex-col-reverse p-6 mt-12"
    >
      <img
        class="md:w-1/2 w-full my-6"
        src="@/assets/TchnoChofLogo.png"
        alt="Tchno Chof Logo"
      />
      <div class="text-primary w-full flex flex-col">
        <div
          data-aos="fade-right"
          class="my-4 w-full flex md:text-left text-center"
        >
          <div data-aos="fade-right" class="md:w-1/2 w-full">
            <h2
              class="xl:text-5xl lg:text-4xl text-3xl font-bold font-Sitka mb-8"
            >
              Performance et durabilité exceptionnelles
            </h2>
            <p class="lg:text-other text-xl leading-tight">
              Techno Chof ne fait aucun compromis sur la qualité. Forts de notre
              héritage de fabrication algérienne et de notre technologie de
              pointe italienne, nous concevons et fabriquons des mitigeurs et
              des tubes multicouches qui offrent performance et durabilité
              exceptionnelles.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          class="my-4 w-full flex md:justify-center md:text-left text-center"
        >
          <div class="md:w-1/2 w-full">
            <h2
              class="xl:text-5xl lg:text-4xl text-3xl font-bold font-Sitka mb-8"
            >
              Design élégant et fonctionnel
            </h2>
            <p class="lg:text-other text-xl leading-tight">
              Chez Techno Chof, nous pensons que la plomberie peut être à la
              fois esthétique et performante. Nos mitigeurs et tubes
              multicouches allient un design moderne et épuré à une
              fonctionnalité optimale.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          class="my-4 w-full flex md:justify-end md:text-left text-center"
        >
          <div class="md:w-1/2 w-full">
            <h2
              class="xl:text-5xl lg:text-4xl text-3xl font-bold font-Sitka mb-8"
            >
              Des Modeles Économiques
            </h2>
            <p class="lg:text-other text-xl leading-tight">
              En privilégiant la production locale de mitigeurs et de tubes
              multicouches, Techno Chof contribue à la réduction des
              importations et à la valorisation du savoir-faire algérien.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex md:flex-row flex-col-reverse gap-4 p-6 mt-12">
      <div data-aos="fade-right" class="">
        <div class="w-full">
          <h2
            class="xl:text-4xl lg:text-3xl text-2xl font-bold font-Sitka mb-8"
          >
            Une expertise technique inégalée
          </h2>
          <p class="lg:text-xl text-lg leading-tight">
            L'entreprise s'appuie sur un savoir-faire italien de pointe, allié à
            la précision et à la rigueur du travail algérien.
          </p>
        </div>
      </div>

      <div data-aos="fade-right" class="">
        <div class="w-full">
          <h2
            class="xl:text-4xl lg:text-3xl text-2xl font-bold font-Sitka mb-8"
          >
            Un engagement envers la qualité
          </h2>
          <p class="lg:text-xl text-lg leading-tight">
            Chaque produit Techno Chof est conçu et fabriqué avec des matériaux
            durables et des technologies innovantes, garantissant une
            performance optimale et une longue durée de vie.
          </p>
        </div>
      </div>
      <div data-aos="fade-right" class="">
        <div class="w-full">
          <h2
            class="xl:text-4xl lg:text-3xl text-2xl font-bold font-Sitka mb-8"
          >
            Une large série de produits
          </h2>
          <p class="lg:text-xl text-lg leading-tight">
            Mitigeurs de cuisine et de salle de bain, tubes multicouches...
            Techno Chof offre une solution complète pour tous vos besoins en
            robinetterie.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.who-we-are::before {
  background: #2d3134;
  height: 2px;
  position: relative;
  top: 12px;
  content: "";
  flex: 1 1;
}

.who-we-are::after {
  background: #2d3134;
  height: 2px;
  position: relative;
  top: 12px;
  content: "";
  flex: 1 1;
}
</style>
