<template>
  <div>
    <nav class="w-full md:w-10/12 mx-auto text-primary pt-4 bg-mainBg hidden lg:flex font-Sitka 2xl:text-5xl text-4xl">
      <ul class="flex w-8/12 mx-auto justify-between">
        <li class="pb-2 mx-4 hover-underline-animation">
          <router-link to="/">Accueil</router-link>
        </li>
        <li class="pb-2 mx-4 hover-underline-animation">
          <router-link to="/about">À propos</router-link>
        </li>
        <li class="pb-2 mx-4 hover-underline-animation">
          <router-link to="/products">Produit</router-link>
        </li>
        <li class="pb-2 mx-4 hover-underline-animation">
          <router-link to="/contact">Contact</router-link>
        </li>
      </ul>
      <!-- <ul class="flex w-4/12 justify-end">
        <li class="pb-2 mx-4 hover-underline-animation">
          <router-link to="/">Login</router-link>
        </li>
        <li class="pb-2 mx-4 hover-underline-animation font-bold">
          <router-link to="/">SignUp</router-link>
        </li>
      </ul> -->
    </nav>
    <nav class="lg:hidden h-24 border-b-2 border-solid border-black font-Sitka">
      <Slide :closeOnNavigation="true">
        <router-link to="/">Accueil</router-link>
        <router-link to="/about">À propos</router-link>
        <router-link to="/products">Produit</router-link>
        <router-link to="/contact">Contact</router-link>
        <!-- <router-link to="/contact">Login</router-link>
        <router-link to="/contact" class="font-semibold">SignUp</router-link> -->
      </Slide>
    </nav>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";
export default {
  components: { Slide },
};
</script>

<style>
.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: -5px;
  left: 0;
  background-color: #2d3134;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>
