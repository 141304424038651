<template>
   <!-- About Header Image  -->
    <div class="p-6">
      <img class="w-full" :src="tchnoChofPicURL" alt="Techno Chof" />
    </div>
</template>

<script>

import tchnoChofPic from '@/assets/TchnoChof.jpg';
export default {
data(){
return{
  tchnoChofPicURL:tchnoChofPic,
}
},
}

</script>

<style>

</style>