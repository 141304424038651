<template>
  <div class="px-5 md:px-10 lg:px-20 text-primary my-10">
    <p class="subtitle fancy overflow-x-hidden hidden md:block">
      <span class="text-other text-primary font-bold font-Sitka">Collection de qualité pour votre maison</span>
    </p>
    <div class="md:flex my-5 md:my-10">
      <div class="md:w-6/12">
        <div class="text-5xl">
          <div class="font-bold font-Sitka">La série Chelia</div>
        </div>
        <p class="my-10 md:pr-20 lg:text-other text-lg">

          Découvrez la série de luxe "Chelia", une fusion parfaite de sophistication et de fonctionnalité pour votre
          espace. Cette série exquise offre une expérience haut de gamme avec ses trois couleurs distinctes: blanc, noir
          et chromé. Chaque produit de la série Chelia incarne l'élégance et l'innovation, tout en offrant une facilité
          d'utilisation optimale. Ajoutez une touche de haut à votre cuisine ou salle de bain avec la série Chelia, et
          transformez votre espace en un sanctuaire de style et de confort.
        </p>
        <!-- <button :class="{ button: true }">voir la serie</button> -->
      </div>
      <div class="md:w-6/12 px-10 md:px-24 mb-20">
        <div class="relative">
          <img class="rounded-3xl" :src="faucet1" alt="faucet1" />
          <div class="absolute img">
            <img class="rounded-3xl" :src="faucet2" alt="faucet2" />
          </div>
        </div>
      </div>
    </div>
    <p class="subtitle fancy fancy-bottom overflow-x-hidden hidden md:block">
      <span class="text-2xl"></span>
    </p>
    <div class="md:flex flex-row-reverse my-5 md:my-10">
      <div class="md:w-6/12">
        <div class="text-5xl">
          <div class="font-bold font-Sitka">La série Timgad</div>
        </div>
        <p class="my-10 md:pr-20 lg:text-other text-lg">
          Découvrez la série "Timgad", une collection polyvalente conçue pour répondre à vos besoins quotidiens. Avec
          une finition chromée élégante, chaque mitigeur de la série Timgad offre une esthétique intemporelle qui
          s'intègre parfaitement à n'importe quel style. La série Timgad propose une variété de types pour convenir à
          toutes vos exigences. Optez pour la simplicité et la qualité avec la série Timgad.
        </p>
        <!-- <button :class="{ button: true }">voir la serie</button> -->

      </div>
      <div class="md:w-6/12 px-10 md:px-24 mb-20">
        <div class="relative">
          <img class="rounded-3xl" :src="faucet1" alt="faucet1" />
          <div class="absolute img">
            <img class="rounded-3xl" :src="faucet2" alt="faucet2" />
          </div>
        </div>
      </div>
    </div>
    <p class="subtitle fancy fancy-bottom overflow-x-hidden hidden md:block">
      <span class="text-2xl"></span>
    </p>
  </div>
</template>

<script>
import faucet1 from "../../assets/faucet1.webp";
import faucet2 from "../../assets/faucet2.jpeg";

export default {
  data() {
    return {
      faucet1,
      faucet2,
    };
  },
};
</script>

<style scoped>
.img {
  width: 50%;
  left: -10%;
  top: 70%;
}

.fancy {
  text-align: left;
}

.fancy span {
  display: inline-block;
  position: relative;
  font-weight: bold;
}

.fancy span:after {
  content: "";
  position: absolute;
  border-top: 1px solid #2d3134;
  top: 50%;
  width: 100vw;
  margin-left: 50%;
}

.fancy-bottom span:after {
  width: 40vw;
  margin-left: 0px;
}

.button {
  cursor: pointer;
  border: 1px black solid;
  font-weight: bold;
  background-color: rgb(240, 240, 240);
  padding: 10px 50px;
  text-transform: uppercase;
}
</style>
