<template>
  <div>
    <div class="contain w-11/12 mx-auto text-primary pb-8">
      <h1
        class="contact-form_title text-4xl my-10 font-bold font-Sitka md:text-5xl"
      >
        Contact Nous
      </h1>

      <div
        id="contact-form"
        class="contact-form flex flex-col-reverse md:flex md:flex-row md:p-14 p-7"
      >
        <div class="md:w-3/6 mt-10 md:mt-0">
          <div
            class="mb-8 flex items-center md:text-other text-xl justify-center md:justify-start"
          >
            <svg-icon
              type="mdi"
              size="40"
              class="mr-4"
              :path="mdiAccountGroupOutline"
            ></svg-icon>
            Information here
          </div>
          <div
            class="my-8 flex items-center md:text-other text-xl justify-center md:justify-start"
          >
            <svg-icon
              type="mdi"
              size="40"
              class="mr-4"
              :path="mdiMessageProcessingOutline"
            ></svg-icon>
            Information here
          </div>
          <div
            class="my-8 flex items-center md:text-other text-xl justify-center md:justify-start"
          >
            <svg-icon
              type="mdi"
              size="40"
              class="mr-4"
              :path="mdiPhoneOutline"
            ></svg-icon>
            Information here
          </div>
          <div
            class="my-8 flex items-center md:text-other text-xl justify-center md:justify-start"
          >
            <svg-icon
              type="mdi"
              size="40"
              class="mr-4"
              :path="mdiShare"
            ></svg-icon>
            Information here
          </div>
        </div>
        <div class="md:w-3/6">
          <form class="form" @submit.prevent="onSubmit">
            <input
              required
              name="email"
              v-model="contact.email"
              placeholder=" Votre email"
              type="email"
              autocomplete="off"
            />
            <textarea
              name="message"
              v-model="contact.message"
              rows="10"
              placeholder="Votre Message pour Nous"
            ></textarea>
            <button
              :disabled="isLoading"
              type="submit"
              :class="{ button: true, disabled: isLoading }"
            >
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiAccountGroupOutline } from "@mdi/js";
import { mdiMessageProcessingOutline } from "@mdi/js";
import { mdiPhoneOutline } from "@mdi/js";
import { mdiShare } from "@mdi/js";
import emailjs from "@emailjs/browser";
export default {
  components: { SvgIcon },
  data() {
    return {
      contact: {
        email: "",
        message: "",
      },
      isLoading: false,
      mdiAccountGroupOutline,
      mdiMessageProcessingOutline,
      mdiPhoneOutline,
      mdiShare,
    };
  },
  methods: {
    /**
     * Clear the form
     */
    clearForm() {
      for (let field in this.contact) {
        this.contact[field] = "";
      }
    },

    /**
     * Handler for submit
     */
    onSubmit() {
      this.isLoading = true;
      emailjs
        .send(
          process.env.VUE_APP_SERVICE_ID,
          process.env.VUE_APP_TEMPLATE_ID,
          {
            email: this.contact.email,
            message: this.contact.message,
          },
          process.env.VUE_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            this.contact = {
              email: "",
              message: "",
            };
            this.$fire({
              title: "Success",
              text: "Email sent with success",
              type: "success",
            });
            console.log(result.text);
            this.isLoading = false;
          },
          (error) => {
            this.$alert("");
            this.$fire({
              title: "Error",
              text: "Error occured when sending the email",
              type: "error",
            });
            console.log(error.text);
            this.isLoading = false;
          }
        );
    },
  },
};
</script>

<style scoped>
.contact-form {
  font-family: 16px;
  min-height: 70vh;
  border-radius: 60px;
  background: rgba(255, 255, 255, 0.8);
}

.contact-form .form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.contact-form_title {
  color: #333;
  text-align: center;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea {
  border: solid 1px #e8e8e8;
  font-family: "Roboto", sans-serif;
  padding: 10px 7px;
  border: 1px gray solid;
  border-radius: 10px;
  margin-bottom: 15px;
  outline: none;
}

.contact-form textarea {
  resize: none;
}

.contact-form .button {
  cursor: pointer;
  border: 1px black solid;
  font-weight: bold;
  background-color: rgb(240, 240, 240);
  float: right;
  padding: 10px 50px;
  align-self: end;
  text-transform: uppercase;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea,
.contact-form .button {
  font-size: 15px;
}
</style>
