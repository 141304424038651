<template>
  <div class="bg-mainBg max-w-screen overflow-hidden">
    <!-- About Header Image  -->
    <SectionOne />
    <!-- Who We Are Section -->
    <SectionTwo />
    <!-- Localization Section -->
    <!-- <SectionThree/> -->

    <!-- FAQ Section -->
    <SectionFour />
  </div>
</template>
<script>
import SectionOne from "../components/About/SectionOne.vue";
import SectionTwo from "../components/About/SectionTwo.vue";
// import SectionThree from "../components/About/SectionThree.vue"
import SectionFour from "../components/About/SectionFour.vue";
export default {
  components: {
    SectionOne,
    SectionTwo,
    // SectionThree,
    SectionFour,
  },
};
</script>
<style scoped></style>
